import { useEffect, useMemo, useState } from 'react';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { StoryShareLinkType, StoryStatus } from '@remento/types/story';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries, useQuery } from '@tanstack/react-query';

import { useAbortableEffect, useAsyncEffect } from '@/hooks';
import { useCollection, useCollectionEntityData, useEntity, useEntityByForeignId } from '@/hooks/useQuery';
import { useServices } from '@/Services';
import { StoryIssue } from '@/services/api/story/story.types';
import { areAllQueriesLoaded } from '@/utils/query';

export function useStoryQuery(storyId: string | null | undefined) {
  const { storyService } = useServices();
  const query = useEntity(EntityType.STORY, storyId, (id, scope) => storyService.getStory(id, scope));
  // If the story has been recently deleted, the backend will not throw the story not found error.
  // So we need to throw it here.
  if (query.data === null) {
    throw new NotFoundError('story-not-found', {
      origin: 'entity',
      entityType: EntityType.STORY,
      entityId: storyId,
    });
  }
  return query;
}

export function usePromptStoryQuery(promptId: string | null | undefined) {
  const { storyService, storyCacheService } = useServices();

  return useEntityByForeignId(
    EntityType.STORY,
    promptId,
    async (id) => {
      const story = await storyCacheService.getPromptStory(id);
      return story?.id ?? null;
    },
    (storyId, scope) => storyService.getStory(storyId, scope),
  );
}

export function useProjectStoriesQuery(projectId: string | null | undefined) {
  const { storyService } = useServices();
  return useCollection(EntityType.STORY, projectId ? { projectId } : null, (params) =>
    storyService.getProjectStories(params.projectId),
  );
}

export function useStoryShareLinkQuery(
  storyId: string | null,
  type: StoryShareLinkType.SOCIAL | StoryShareLinkType.HIGHLIGHT = StoryShareLinkType.SOCIAL,
) {
  const { storyService } = useServices();
  return useQuery({
    enabled: storyId !== null,
    queryKey: ['story-share-link', storyId, type].filter(notNull),
    queryFn: async ({ signal }) => {
      // The id will never be null here.
      return storyService.getShareLink(storyId ?? '', type, { signal });
    },
  });
}

export function useFilterStoriesIds(storyIds: string[] | null, status: StoryStatus): string[] | null {
  const { entityCacheManagerService, storyService } = useServices();

  const storiesQueries = useQueries(
    useMemo(
      () => ({
        queries: (storyIds ?? []).map((storyId) =>
          entityCacheManagerService.buildEntityQuery(EntityType.STORY, storyId, (_, scope) =>
            storyService.getStory(storyId, scope),
          ),
        ),
      }),
      [entityCacheManagerService, storyIds, storyService],
    ),
  );

  const [filteredStoriesIds, setFilteredStoriesIds] = useState<string[] | null>(null);
  useEffect(() => {
    if (storyIds === null || areAllQueriesLoaded(storiesQueries) === false) {
      setFilteredStoriesIds(null);
      return;
    }

    setFilteredStoriesIds((currentFilteredStoriesIds) => {
      const newFilteredStoriesIds: string[] = [];
      for (const query of storiesQueries) {
        if (query.data?.status !== status) {
          continue;
        }
        newFilteredStoriesIds.push(query.data.id);
      }

      if (newFilteredStoriesIds.length === currentFilteredStoriesIds?.length) {
        for (let i = 0; i < newFilteredStoriesIds.length; i += 1) {
          if (newFilteredStoriesIds[i] !== currentFilteredStoriesIds[i]) {
            return newFilteredStoriesIds;
          }
        }
        return currentFilteredStoriesIds;
      }

      return newFilteredStoriesIds;
    });
  }, [storiesQueries, status, storyIds]);

  return filteredStoriesIds;
}

export function usePollStoriesIds(storyIds: string[] | null, waitUntilStatus: StoryStatus, interval?: number): void {
  const { entityCacheManagerService, storyService } = useServices();

  useAbortableEffect(
    (signal) => {
      if (storyIds === null || storyIds.length === 0) {
        return;
      }

      for (const storyId of storyIds) {
        entityCacheManagerService.observeEntity({
          entityType: EntityType.STORY,
          entityId: storyId,
          fetchCallback: ({ signal }) => storyService.getStory(storyId, { signal }),
          stopCallback: (story) => story.status === waitUntilStatus,
          signal,
          interval,
        });
      }
    },
    [waitUntilStatus, storyService, storyIds, entityCacheManagerService, interval],
  );
}

export function useStoryIssues(storyId: string | null) {
  const { storyCacheService } = useServices();
  const [issues, setIssues] = useState<StoryIssue[]>();
  const storyQuery = useStoryQuery(storyId);
  const story = storyQuery.data;

  useEffect(() => {
    if (story == null) {
      return;
    }

    storyCacheService.getStoryIssues(story).then(setIssues);
  }, [storyCacheService, storyId, story]);

  return issues;
}

export function useStoriesIssues(storyIds: string[]) {
  const { storyCacheService, storyService } = useServices();
  const [issues, setIssues] = useState<StoryIssue[] | null | undefined>();
  const stories = useCollectionEntityData(storyIds, EntityType.STORY, (id) => storyService.getStory(id));

  useAsyncEffect(
    async (checkpoint) => {
      if (stories == null) {
        setIssues(stories);
        return;
      }
      const storyIssues = await Promise.all(
        stories.map(async (story) => await storyCacheService.getStoryIssues(story)),
      );
      checkpoint();
      setIssues(storyIssues.flat());
    },
    [storyCacheService, stories],
  );

  return issues;
}
